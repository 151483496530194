import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ListingImageGallery from '../../containers/ListingPage/ListingImageGallery/ListingImageGallery';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

const MediaCarousel = ({ listingId }) => {
  const isVideo = extension => extension === 'mp4';
  const isModel = extension => extension === 'glb' || extension === 'gltf';
  const { id } = useParams();
  const listingID = listingId || id;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await fetch(`/api/media/${listingID}/`);
      const data = await response.json();

      setFiles(data);
    };
    fetchFiles();
  }, [listingID]);

  return (
    <ListingImageGallery
      images={files.map(file => {
        const extension = file.url.split('.').pop();
        const URL = encodeURI(file.url);

        return {
          id: new UUID(file.url),
          type: isVideo(extension) ? 'video' : isModel(extension) ? 'model' : 'image',
          attributes: {
            variants: {
              original: {
                name: URL,
                width: 320,
                height: 240,
                url: URL,
              },
            },
          },
        };
      })}
      imageVariants={['original']}
    />
  );
};
export default MediaCarousel;
